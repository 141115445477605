
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  Search,
  Icon,
  Col,
  Row,
  Empty,
  Button,
  Dialog,
  Cell,
  Image as VanImage,
  Tab,
  Tabs,
  Sticky,
  NavBar,
  Toast,
  List,
  NoticeBar,
} from "vant";
import CancelPayment from "@/components/CommonPayment/CancelPayment.vue";
import WaterfallLayout from "@/views/Mall/Details/waterfallLayout.vue";
import AdvertsBanner from "@/views/ProjectDetail/components/AdvertsBanner.vue";
import { monitorEvent } from "@/utils/youMengMonitor";
import EventBus from "@/utils/eventBus";

declare type OrderType =
  | "RECHARGE"
  | "SHOW"
  | "COMMODITY"
  | "VIDEO"
  | "ACTIVITYAPPLY";

@Component({
  components: {
    WaterfallLayout,
    CancelPayment,
    [NoticeBar.name]: NoticeBar,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sticky.name]: Sticky,
    AdvertsBanner,
  },
})
export default class PaySuccess extends Mixins(Mixin) {
  resultStatus: "LOADING" | "SUCCESS" | "CANCLE" = "LOADING"; // 支付结果
  billNo = ""; // 支付订单号，取消支付时要用
  orderId = "";
  goloading = false;
  actuallyPaidAmt = "";
  videoId = ""; // 云剧院，视频 ID
  recommend = true;
  productSourceEnum = "";
  searchLength = 0; // 有无搜索结果
  projectList: Array<good.RecommendProductDto> = [];
  toursList: Array<good.RecommendProductDto> = [];
  routeSign = "";
  total = 0; // 所有演出总数
  orderData: ObjectMap = {};
  orderType: OrderType = "SHOW";
  showResult: Array<good.RecommendProductDto> = []; // 演出搜索结果（非巡演）
  payMethod = "";
  classifyList: Array<good.CommodityDto> = [];
  loading = false;
  finished = false;
  pages: number | undefined = 1; // 总页数
  page = 1;
  size = 10;
  productId = "";
  isBackFrom = "";
  showDownload = false; // 是否显示关闭悬浮框
  canClick = false;
  step = false;
  activityId = ""; //活动 id

  /**
   * 是否是微信支付
   */
  get isWXPAY(): boolean {
    return this.payMethod == "WXPAY";
  }

  /**
   * 是否是演出
   */
  get isShow(): boolean {
    return this.routeSign === "ticket";
  }

  /**
   * 是否展示广告位或推荐
   * 1）演出（非商品）
   * 2）不是渠道分销的情况
   * 3）不是微信支付的情况（微信支付是展示在点金页的 iframe 中）
   */
  get isShowAdvertAndRecommend(): boolean {
    return this.isShow && !this.isChannelDistribution && !this.isWXPAY;
  }

  /**
   * 是否展示下载 APP 的提示
   * 1）showDownload 为 true
   * 2）不是渠道分销的情况
   * 3）不是微信支付的情况（微信支付是展示在点金页的 iframe 中）
   */
  get isShowDownload(): boolean {
    return this.showDownload && !this.isChannelDistribution && !this.isWXPAY;
  }

  created(): void {
    EventBus.$emit("refreshActiveOrderList");
    this.showDownload =
      localStorage.getItem("downloadAppData") !== this.getData();
  }

  mounted(): void {
    this.resultStatus = "LOADING";
    this.init();
    if (this.isWXPAY) {
      // 微信支付，点金页
      window.onload = function () {
        parent.postMessage(
          JSON.stringify({
            action: "onIframeReady",
            displayStyle: "SHOW_CUSTOM_PAGE",
          }),
          "https://payapp.weixin.qq.com"
        );
      };
    }
    let query = this.$route.query;
    let status = query.status;
    if (
      (this.isWXPAY && status == "WAIT_BUYER_PAY") ||
      (this.payMethod == "ALIPAY" &&
        (status == "NEW_ORDER" || status == "WAIT_BUYER_PAY"))
    ) {
      /**
       * 微信支付，用户点击了取消支付：payMethod == "WXPAY" && query.status == "WAIT_BUYER_PAY"
       * 支付宝支付，用户点击了取消支付：payMethod == "ALIPAY" && (status == "NEW_ORDER" || status == "WAIT_BUYER_PAY")
       * 此时展示取消支付组件
       */
      this.resultStatus = "CANCLE";
    } else if (query.status == "TRADE_SUCCESS") {
      monitorEvent("PaySuccess", "支付成功"); // 埋点：确认支付页，支付成功
      // 云剧院，支付成功，直接路由到剧院详情页
      if (this.routeSign === "video") {
        this.$router.replace(`/cloud-theatre-details?id=${this.videoId}`);
      } else {
        // 其他支付类型，且支付成功
        this.resultStatus = "SUCCESS";
        if (this.isShow) {
          this.classifyDetails();
        }
      }
    }
  }

  closeDownLoad(): void {
    localStorage.setItem("downloadAppData", this.getData());
    this.showDownload = false;
  }

  init(): void {
    this.routeSign = this.$route.params.sign;
    let query = this.$route.query;
    this.productId = !!query.productId
      ? (query.productId as string)
      : this.productIdModule.productId;
    if (query) {
      this.billNo = query.billNo ? String(query.billNo) : "";
      this.videoId = query.videoId ? String(query.videoId) : "";
      this.payMethod = String(query.payMethod || "");
      this.orderId = `${query.orderId}`;
      this.productSourceEnum = String(query.productSourceEnum || "");
      this.activityId = query.activityId ? String(query.activityId) : "";

      let orderType = String(query.orderType);
      if (orderType === "ORDER_TICKET" || orderType === "SHOW") {
        this.orderType = "SHOW";
      } else if (orderType === "ORDER_GOOD" || orderType === "COMMODITY") {
        this.orderType = "COMMODITY";
      } else if (orderType === "RECHARGE") {
        this.orderType = "RECHARGE";
      } else if (orderType === "ACTIVITYAPPLY") {
        orderType = "ACTIVITYAPPLY";
      } else {
        this.orderType = "VIDEO";
      }

      if (query.actuallyPaidAmt && query.actuallyPaidAmt === "0") {
        this.getPayStatus(); //
      } else {
        this.canClick = false;
      }
    } else {
      this.canClick = false;
      this.$router.go(-1);
    }
  }

  getPayStatus(): void {
    //轮询 检测支付状态
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    let obj = {
      orderId: this.orderId,
      orderType: this.orderType,
    };
    this.$api.paymentApi.paymentOrder.payStatus(obj, ({ data }) => {
      if (data) {
        this.canClick = false;
        Toast.clear();
      } else {
        this.getPayStatus();
      }
    });
  }

  /*
   * 频道详情
   */
  classifyDetails(): void {
    let da = {
      page: this.page,
      size: this.size,
    };
    if (this.page === 1) {
      this.classifyList = [];
      this.$api.goodApi.commodity.findCommoditiesPeriphery(
        `${this.productId}`,
        "COMMODITY_PRODUCT",
        da,
        ({ data }) => {
          if (data.records && data.records.length !== 0) {
            this.step = true;
            this.loading = false;
            this.pages = data.pages;
            data.records.forEach((value: good.CommodityDto) => {
              if (value.lowestMemberPrices) {
                value.lowestMemberPrices = value.lowestMemberPrices.filter(
                  (item) => {
                    if (item.memberGradeId == this.UserModule.memberLevelId) {
                      return (
                        item.memberGradeId == this.UserModule.memberLevelId
                      );
                    } else {
                      return item.memberGradeId == "0";
                    }
                  }
                );
              }
            });
            if (data.records) {
              this.classifyList = this.classifyList.concat(data.records || []);
            }
            // 数据全部加载完成
            if (this.page >= Number(this.pages)) {
              this.finished = true;
              this.loading = false;
            } else {
              this.page++;
            }
          } else {
            this.step = false;
            this.otherInit();
          }
        },
        (error) => {
          this.loading = false;
          Toast(error);
        }
      );
    } else {
      if (this.step) {
        this.$api.goodApi.commodity.findCommoditiesPeriphery(
          `${this.productId}`,
          "COMMODITY_PRODUCT",
          da,
          ({ data }) => {
            if (data.records && data.records.length !== 0) {
              this.loading = false;

              data.records.forEach((value: good.CommodityDto) => {
                if (value.lowestMemberPrices) {
                  value.lowestMemberPrices = value.lowestMemberPrices.filter(
                    (item) => {
                      if (item.memberGradeId == this.UserModule.memberLevelId) {
                        return (
                          item.memberGradeId == this.UserModule.memberLevelId
                        );
                      } else {
                        return item.memberGradeId == "0";
                      }
                    }
                  );
                }
              });
              if (data.records) {
                this.classifyList = this.classifyList.concat(
                  data.records || []
                );
              }
            }
          }
        );
      }
    }
  }
  otherInit() {
    let da = {
      page: 1,
      size: this.size,
    };
    this.$api.goodApi.commodity.findRecommend(da, ({ data }) => {
      this.loading = false;
      this.pages = data.pages;

      if (data.records) {
        data.records.forEach((value: good.CommodityDto) => {
          value.lowestMemberPrices = value.lowestMemberPrices?.filter(
            (item) => {
              if (item.memberGradeId == this.UserModule.memberLevelId) {
                return item.memberGradeId == this.UserModule.memberLevelId;
              } else {
                return item.memberGradeId == "0";
              }
            }
          );
        });
      }
      this.classifyList = this.classifyList.concat(data.records || []);
      // console.log("2222222222222", this.classifyList, data.records);
      this.finished = true;
      this.loading = false;
    });
  }

  onLoad(): void {
    this.loading = true;
    this.classifyDetails();
  }
  getData(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + month + "-" + day;
  }
  goMallDetails(itemInfo: good.CommodityDto): void {
    monitorEvent(
      "PaySuccess_ClickGood",
      "支付成功页点击一个推荐商品",
      itemInfo.commodityAbbr
    ); // 埋点：支付成功页点击一个推荐商品
    let path = `/mall/details?commodityId=${itemInfo.commodityInfoId}`;
    this.isBackFrom = path;
    this.$router.push(path);
  }
  downloadApp(): void {
    this.$router.push("/mine/download-app");
  }

  // 返回主页
  goHome(): void {
    monitorEvent("PaySuccess_ClickHome", "支付成功页点击返回首页"); // 埋点：支付成功页点击返回首页
    let path = "/home";
    if (this.isChannelDistribution) {
      // 渠道分销
      path = `/channeldistribution/${
        this.isCustomChannelDistribution ? "custom" : "common"
      }`;
    }
    if (this.isWXPAY) {
      // 微信支付，点金页
      this.jumpOut(this.getCompleteUrl(path));
    } else {
      this.$router.replace(path);
    }
  }

  /**
   * 微信支付点金页，从 iframe 向外部跳转
   */
  jumpOut(jumpOutUrl: string): void {
    parent.postMessage(
      JSON.stringify({
        action: "jumpOut",
        jumpOutUrl,
      }),
      "https://payapp.weixin.qq.com"
    );
  }

  /**
   * 查看订单
   */
  goOrderDetail(): void {
    monitorEvent("PaySuccess_ClickViewOrder", "支付成功页点击查看订单"); // 埋点：支付成功页点击查看订单
    let path =
      this.routeSign === "good"
        ? `/mall/m-order/${this.orderId}`
        : this.routeSign === "activity"
        ? `/apply-list?activityId=${this.activityId}`
        : `/performance/per-order/${this.orderId}/${this.productSourceEnum}`;
    if (this.isWXPAY) {
      // 微信支付，点金页
      this.jumpOut(this.getCompleteUrl(path));
    } else {
      this.goloading = true;
      this.$router.push(path);
    }
  }
  // 返回
  onClickLeft(): void {
    this.$router.go(-2);
  }
}
