
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Image, Swipe, SwipeItem, Row } from "vant";
@Component({
  components: {
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Row.name]: Row,
  },
})
export default class AdvertsBanner extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
  })
  advertList?: cms.TheaterAdvertDto[];
  public advertsList: Array<cms.TheaterAdvertDto> = [];
  public current = "0";
  @Prop({ default: false })
  ifPaySuccessBanner?: boolean;
  created(): void {
    if (this.ifPaySuccessBanner) {
      this.getAdverBanner();
    } else {
      this.advertsList = this.advertList || [];
    }
  }
  getAdverBanner(): void {
    let type:
      | "APP_BANNER"
      | "APP_POPUP"
      | "APP_RECOMMEND_ADVERT"
      | "APP_NOTICE"
      | "APP_PROJECT_ADVERT"
      | "PAY_SUCCESS"
      | "MALL_BANNER"
      | "PC_POPUP"
      | "PC_BOTTOM_FLOAT_LAYER"
      | "PC_BANNER"
      | "FLAGSHIP_STORE_BANNER"
      | "FLAGSHIP_STORE_FALLS_ADVERT"
      | "FLAGSHIP_STORE_SLIDE_SHOW"
      | "FLAGSHIP_STORE_LATEST_NEWS"
      | "FLAGSHIP_STORE_NEWS_FOCUS"
      | "FLAGSHIP_STORE_AFFICHE"
      | "FLAGSHIP_STORE_PRODUCT"
      | "FLAGSHIP_STORE_MALL_BANNER"
      | "PC_NOTICE"
      | "CLOUD_THEATER_BANNER"
      | "CLOUD_THEATER_TOP"
      | "CLOUD_THEATER_BOTTOM"
      | "CLOUD_THEATER_VIDEO"
      | "APP_OPEN_SCREEN"
      | "LIMIT_COUPON";
    if (this.ifPaySuccessBanner) {
      type = "PAY_SUCCESS";
    } else {
      type = "APP_PROJECT_ADVERT";
    }
    this.$api.cmsApi.advert.cityAdverts(type, (data) => {
      this.advertsList = data.data;
    });
  }
  onChange(index: string): void {
    this.current = index;
  }
}
